import { BaseController } from 'controllers/BaseController'
import { LengthUnitType } from 'features/measurementSystem/utils/selectableAbbreviations'
import {
  PartyProfileDto,
  QuantityDto,
  QuantityDtoType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import store from 'store/configureStore'
import { newUserActions } from 'store/User/newUserReducer'
import { IOrganizationProfileAPI } from './IOrganizationProfileAPI'
import { OrganizationProfileAPI } from './OrganizationProfileAPI'

const fallbackVatQuantity: QuantityDto = {
  value: 21,
  unit: '%',
  quantityType: QuantityDtoType.Ratio,
}

export class OrganizationProfileController extends BaseController<IOrganizationProfileAPI> {
  constructor() {
    super((onRequestChanged) => new OrganizationProfileAPI(onRequestChanged))
  }

  public async getVatPercentage(): Promise<QuantityDto> {
    try {
      const organizationContext = store.getState().user.organizationContext

      if (!organizationContext) return fallbackVatQuantity

      const currentVat = organizationContext?.defaultVatRatio

      if (currentVat) return currentVat

      const partyId = organizationContext.id

      const vatPercentage = (await this.api.getPartyProfile(partyId))
        .vatPercentage

      store.dispatch(newUserActions.setDefaultVatRatio(vatPercentage))

      return vatPercentage ?? fallbackVatQuantity
    } catch (err) {
      this.HandleError(err)
      return fallbackVatQuantity
    }
  }

  public async getPartyProfile(organizationId) {
    return await this.api.getPartyProfile(organizationId)
  }

  public async getOrganizationLogo(organizationId: string) {
    try {
      return await this.api.getLogo(organizationId)
    } catch (err) {
      if (err?.['status'] === 404) return null
      throw err
    }
  }

  public async postOrganizationLogo(organizationId: string, file: File) {
    try {
      return await this.api.postLogo(organizationId, file)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async updatePartyProfile(
    partyId: string,
    partyProfile: PartyProfileDto
  ) {
    try {
      const resp = await this.api.updatePartyProfile(partyId, partyProfile)

      if (partyProfile.vatPercentage) {
        store.dispatch(newUserActions.setDefaultVatRatio(resp.vatPercentage))
      }

      store.dispatch(newUserActions.setDateTimeFormat(resp.dateFormat))

      if (resp.legalName) {
        store.dispatch(newUserActions.setPartyLegalName(resp.legalName))
      }
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async updatePartyDefaultLengthUnit(
    partyId: string,
    lengthUnit: LengthUnitType
  ) {
    const currentLengthUnit =
      store.getState().user.organizationContext.defaultLengthAbbreviation

    try {
      store.dispatch(
        newUserActions.setDefaultLengthUnit(lengthUnit.abbreviation)
      )

      await this.api.updateDefaultLengthUnit(partyId, {
        selectedLengthUnit: lengthUnit.name,
      })
    } catch (err) {
      store.dispatch(newUserActions.setDefaultLengthUnit(currentLengthUnit))
      throw this.HandleError(err)
    }
  }

  public async saveDateFormat(dateFormat: string) {
    try {
      await this.api.saveDateFormat(dateFormat)
    } catch (err) {
      throw this.HandleError(err)
    }
  }
}

import { Typography } from '@mui/material'
import { Templates } from '@rjsf/mui'
import { BaseInputTemplateProps } from '@rjsf/utils'
import { useTranslation } from 'react-i18next'
import { dashify } from 'utils/dashify'

const { BaseInputTemplate } = Templates

export function RJSFBaseInputTemplate(props: BaseInputTemplateProps) {
  const { t } = useTranslation()

  const label =
    props.uiSchema['ui:title']?.toString() ||
    props.uiSchema['ui:label']?.toString()

  let translatedLabel = undefined

  if (label) {
    const translationKey = `ws-${
      props.uiSchema['ui:label-translation-key'] || dashify(label)
    }`

    translatedLabel = t(translationKey, { defaultValue: label, ns: 'project' })
  }

  const description = props.uiSchema['ui:description-translation-key']
    ? t(props.uiSchema['ui:description-translation-key'], {
        defaultValue: props.uiSchema['ui:description'],
        ns: 'project',
      })
    : props.uiSchema['ui:description']

  let validation = undefined

  if (props.uiSchema['min-value'] != null) {
    try {
      const valueNumber = Number(props.value ?? 0)
      if (isNaN(valueNumber)) {
        validation = 'should be a number'
      } else if (valueNumber <= props.uiSchema['min-value']) {
        validation = t('min-value', {
          minValue: props.uiSchema['min-value'],
          ns: 'common',
        })
      }
    } catch (err) {
      console.error('cant validate ' + props.value, err)
    }
  }

  if (props.uiSchema['max-value'] != null) {
    try {
      const valueNumber = Number(props.value ?? 0)

      if (isNaN(valueNumber)) {
        validation = 'should be a number'
      } else if (valueNumber > props.uiSchema['max-value']) {
        validation = validation = t('max-value', {
          maxValue: props.uiSchema['max-value'],
          ns: 'common',
        })
      }
    } catch (err) {
      console.error('cant validate ' + props.value, err)
    }
  }

  if (props.rawErrors?.length ?? validation) {
    setTimeout(() => {
      const errors = props.rawErrors?.join(' | ') || validation

      props.formContext.onValidationError({
        [props.id]: {
          __errors: errors,
        },
      })
    })
  } else {
    props.formContext.onValidationError({
      [props.id]: {
        __errors: undefined,
      },
    })
  }

  return (
    <>
      <BaseInputTemplate
        {...props}
        label={translatedLabel}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFocusCapture={(e: any) => e.target.select()}
        fullWidth
        error={Boolean(props.rawErrors?.length) || Boolean(validation)}
        helperText={
          <Typography variant="caption" whiteSpace={'normal'} component="span">
            {props.rawErrors?.join(' ') || validation || description}
          </Typography>
        }
      />
      {/* {props.uiSchema['ui:description-translation-key']} */}
      {/* <JsonViewer data={props.uiSchema} /> */}
      {/* <JsonViewer data={props.schema} /> */}
      {/* <JsonViewer data={props.onError} /> */}
    </>
  )
}

import {
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  TextField,
} from '@mui/material'
import { DialogCloseSaveButtons } from 'components/Common/DialogActionButtons/DialogCloseSaveButtons'
import { QuantityField } from 'components/Common/UnitEditor/QuantityField'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import { useAppController } from 'customHooks/useAppController'
import { ProjectSupplierLogo } from 'features/BillOfMaterials/ProjectHeader/ProjectSupplierLogo'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { isEqual } from 'lodash'
import { FormEvent, FormEventHandler, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AddressDto,
  ContactPersonDto,
  ProjectDetailsDto,
  QuantityDto,
  UpdateProjectDetailsRequest,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { useAppSelector } from 'store/configureStore'
import { makeStyles } from 'tss-react/mui'
import { deepCompareIgnoringKeys } from 'utils/deepCompare'
import { ProjectDetailsController } from '../ProjectDetailsController'
import { ProjectAddressForm } from './ProjectAddressForm'

type ProjectContactInfoDialogProps = {
  open: boolean
  onClose: () => void
}

const useStyles = makeStyles({ name: 'ProjectDetailsDialog' })((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 3),
    gap: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    textTransform: 'lowercase',
  },
  contactPersonForm: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 'bold',
  },
  titleDiv: {
    marginBottom: theme.spacing(1),
  },
  contactPersonTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  // autoComplete: {
  //   width: '50%',
  //   minWidth: '250px',
  // },
  projectDetailsForm: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}))

export const ProjectDetailsDialog = (props: ProjectContactInfoDialogProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation('project')

  const contactInfo = useAppSelector(
    projectSelectors.projectBuyerContactInfoSelector,
    isEqual
  )

  const isBuyerView = useAppSelector(
    projectSelectors.projectInBuyerViewSelector
  )

  const projectReference = useAppSelector(
    projectSelectors.projectReferenceSelector
  )
  const orderNumber = useAppSelector(
    projectSelectors.projectOrderNumberSelector
  )

  const { controller } = useAppController(() => new ProjectDetailsController())

  const [projectDetails, setProjectDetails] = useState<ProjectDetailsDto>()

  const [deliveryAddress, setDeliveryAddress] = useState<null | AddressDto>(
    null
  )
  const [postalAddress, setPostalAddress] = useState<null | AddressDto>(null)

  const [vatRatio, setVatRatio] = useState<QuantityDto>(null)

  const [postalAddressAsDeliveryAddress, setPostalAddressAsDeliveryAddress] =
    useState<boolean | null>(null)

  const buyerParty = useAppSelector(
    projectSelectors.projectBuyerSelector,
    isEqual
  )

  const isEditable = useAppSelector(
    projectSelectors.ProjectEditableStateSelector
  )

  useEffect(() => {
    controller
      .getProjectDetails()
      .then((projectDetails) => {
        setProjectDetails(projectDetails)
        setPostalAddress(projectDetails?.postalAddress)

        if (
          deepCompareIgnoringKeys(
            projectDetails?.deliveryAddress,
            projectDetails?.postalAddress
          )
        ) {
          setPostalAddressAsDeliveryAddress(true)
        } else {
          setPostalAddressAsDeliveryAddress(false)
        }

        setDeliveryAddress(projectDetails?.deliveryAddress)
        setVatRatio(projectDetails.vatRatio)
      })
      .catch((err) => ShowException('Quotation Details', err))
  }, [controller])

  function GetContactPersonRequest(e: FormEvent<HTMLFormElement>) {
    const formData = new FormData(e.currentTarget)

    const newContactPerson: ContactPersonDto = {
      firstName: formData.get('firstName') as string,
      lastName: formData.get('lastName') as string,
      email: formData.get('email') as string,
    }

    if (
      !deepCompareIgnoringKeys(newContactPerson, projectDetails.contactPerson, [
        'id',
      ])
    ) {
      return newContactPerson
    }

    return null
  }

  function GetAddressRequest() {
    const req: { postalAddress?: AddressDto; deliveryAddress?: AddressDto } = {}

    if (postalAddressAsDeliveryAddress) {
      req.deliveryAddress = postalAddress
    }

    if (!isEqual(projectDetails.deliveryAddress, deliveryAddress)) {
      req.deliveryAddress = deliveryAddress
    }

    if (!isEqual(projectDetails.postalAddress, postalAddress)) {
      req.postalAddress = postalAddress
    }

    return req
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    try {
      e.preventDefault()

      const contactPerson = GetContactPersonRequest(e)
      const addresses = GetAddressRequest()

      const formData = new FormData(e.currentTarget)

      const req: UpdateProjectDetailsRequest = {
        projectReference: formData.get('projectReference') as string,
        orderNumber: formData.get('orderNumber') as string,
        vatRatio: vatRatio,
        buyingPartyName: formData.get('buyingPartyName') as string,
        contactPerson: contactPerson,
        postalAddress: addresses.postalAddress,
        deliveryAddress: addresses.deliveryAddress,
      }

      await controller.updateProjectDetails(req)

      props.onClose()
    } catch (err) {
      ShowException('Unable to update', err)
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={(e, reason) => reason === 'escapeKeyDown' && props.onClose()}
      fullWidth
      maxWidth="lg"
      disableRestoreFocus
    >
      <div
        style={{
          display: 'flex',
          padding: '1rem 1.5rem',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <LocalizedTypography
          translationKey="project:project-details"
          variant="h6"
        >
          project details
        </LocalizedTypography>
        <ProjectSupplierLogo disableClick />
      </div>
      <form onSubmit={handleSubmit} id="contact-person-form">
        <DialogContent className={classes.dialogContent}>
          <div className={classes.content}>
            <div className={classes.projectDetailsForm}>
              <TextField
                onFocus={(e) => e.target.select()}
                name="orderNumber"
                label={t('project:order-number')}
                defaultValue={orderNumber}
                inputProps={{
                  maxLength: 50,
                }}
                style={{
                  minWidth: '20ch',
                  maxWidth: '60ch',
                }}
                disabled={isBuyerView}
              />
              <TextField
                onFocus={(e) => e.target.select()}
                name="projectReference"
                label={t('project:project-reference')}
                defaultValue={projectReference}
                required
                fullWidth
                inputProps={{
                  maxLength: 50,
                }}
                style={{
                  width: '100%',
                }}
              />
              <QuantityField
                value={vatRatio}
                onChange={(quantity) => setVatRatio(quantity as QuantityDto)}
                label={t('project:vat-ratio-no-unit', 'VAT')}
                disabled={!isEditable || isBuyerView}
                decimalScale={3}
                fixedDecimalScale={false}
              />
            </div>
          </div>

          <div className={classes.content}>
            <LocalizedTypography
              translationKey="project:buying-party"
              variant="subtitle1"
              className={classes.title}
            >
              Buyer Company
            </LocalizedTypography>
            <TextField
              onFocus={(e) => e.target.select()}
              name="buyingPartyName"
              defaultValue={
                projectDetails?.buyerCompanyName ?? buyerParty?.legalName
              }
              required
              disabled={isBuyerView}
              fullWidth
              inputProps={{
                maxLength: 50,
              }}
            />
          </div>

          <div className={classes.content}>
            <div className={classes.contactPersonTitle}>
              <LocalizedTypography
                translationKey="project:contact-person"
                variant="subtitle1"
                className={classes.title}
              >
                Contact Person
              </LocalizedTypography>
            </div>
            <div className={classes.contactPersonForm}>
              <TextField
                onFocus={(e) => e.target.select()}
                name="firstName"
                label={t('common:first-name')}
                defaultValue={contactInfo?.firstName}
                variant="standard"
                fullWidth
                inputProps={{
                  maxLength: 50,
                }}
              />
              <TextField
                onFocus={(e) => e.target.select()}
                name="lastName"
                label={t('common:last-name')}
                defaultValue={contactInfo?.lastName}
                variant="standard"
                fullWidth
                inputProps={{
                  maxLength: 50,
                }}
              />
              <TextField
                onFocus={(e) => e.target.select()}
                name="email"
                label={t('common:email')}
                defaultValue={contactInfo?.email}
                type="email"
                variant="standard"
                fullWidth
                inputProps={{
                  maxLength: 80,
                }}
              />
            </div>
          </div>
          <div className={classes.content}>
            <ProjectAddressForm
              address={postalAddress}
              setAddress={setPostalAddress}
              titleText={t('common:postal-address', 'postal address')}
              classes={{ titleText: classes.title, titleDiv: classes.titleDiv }}
              disabled={!isEditable}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={postalAddressAsDeliveryAddress ?? false}
                  onChange={(e) =>
                    setPostalAddressAsDeliveryAddress(e.target.checked)
                  }
                  size="small"
                  disabled={!isEditable}
                />
              }
              label={
                <LocalizedTypography
                  translationKey="common:use-postal-address-as-delivery-address"
                  variant="body2"
                  color="primary"
                >
                  Use postal address as delivery address
                </LocalizedTypography>
              }
            />
          </div>
          <Collapse in={postalAddressAsDeliveryAddress === false}>
            <div className={classes.content}>
              <ProjectAddressForm
                address={deliveryAddress}
                setAddress={setDeliveryAddress}
                disabled={!isEditable}
                // handleCopyAddress={() =>
                //   handleCopyAddress({
                //     from: 'postalAddress',
                //     to: 'deliveryAddress',
                //   })
                // }
                // copyText={t(
                //   'common:copy-address-postal-as-delivery',
                //   'use postal address as delivery address'
                // )}
                titleText={t('common:delivery-address', 'delivery address')}
                classes={{ titleText: classes.title }}
              />
            </div>
          </Collapse>
        </DialogContent>
      </form>
      <DialogActions>
        <DialogCloseSaveButtons
          onCloseButtonClicked={props.onClose}
          saveButtonDisabled={false}
          saveButtonFormId="contact-person-form"
        />
      </DialogActions>
    </Dialog>
  )
}

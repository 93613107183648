import { Divider, IconButton, Typography } from '@mui/material'
import { DefinitiveInputValidators } from 'components/DefinitiveInputs/Validators'
import { CostDriversActions } from 'features/BillOfMaterials/store/asyncActions/CostDriversActions'
import { ProjectTextField } from 'features/BillOfMaterials/store/components/ProjectTextField'
import { projectOperationIsPendingSelector } from 'features/BillOfMaterials/store/selectors/projectOperationPendingSelector'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { HomePriceButtonOutlined } from 'features/BomItemDetailsDrawer/BomItemDetails/ProjectDetails/ProjectPriceSummaries/HomePriceButtonOutlined'
import { MoneyString } from 'model/Money'
import { QuantityString } from 'model/Quantity'
import { TimeString } from 'model/TimeString'
import { useTranslation } from 'react-i18next'
import {
  PriceSummaryDto,
  QuantityDtoType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppDispatch, useAppSelector } from 'store/configureStore'

export const MarginEditor = (props: {
  // data: PriceSummaryDto[]
  selectedData: PriceSummaryDto
  setData: (priceSummary: PriceSummaryDto) => void
  setSelectedData: (priceSummary: PriceSummaryDto) => void
  disabled?: boolean
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const projectIsEditable = useAppSelector(
    projectSelectors.ProjectEditableStateSelector
  )

  const resetingDiscount = useAppSelector(
    projectOperationIsPendingSelector(
      CostDriversActions.ResetCostDriverDiscountToDefault.typePrefix
    )
  )
  const resetingSurcharge = useAppSelector(
    projectOperationIsPendingSelector(
      CostDriversActions.ResetCostDriverSurchargeToDefault.typePrefix
    )
  )

  const handleResetDiscountToDefault = () => {
    dispatch(
      CostDriversActions.ResetCostDriverDiscountToDefault({
        priceSummary: props.selectedData,
      })
    )
  }

  const handleResetSurchargeToDefault = () => {
    dispatch(
      CostDriversActions.ResetCostDriverSurchargeToDefault({
        priceSummary: props.selectedData,
      })
    )
  }

  if (!props.selectedData) {
    return null
  }

  return (
    <div className="margin-editor">
      <div className="selected-data">
        <label className="label">
          {props.selectedData.description.replace('-', '\n')}
        </label>
        <div style={{ textAlign: 'right' }}>
          {props.selectedData?.isMaterial ? (
            <Typography variant="caption">
              {QuantityString(props.selectedData.weight)}
            </Typography>
          ) : (
            <Typography variant="caption">
              {TimeString(props.selectedData.productionTime)}
            </Typography>
          )}
        </div>

        <ul>
          <li>
            {props.disabled ? (
              <>
                <label>cost price:</label>
                <label className="label">
                  {QuantityString(props.selectedData.discountRatio)}
                </label>
              </>
            ) : props.selectedData.isPurchasePart ||
              props.selectedData?.isPurchasePartWithoutArticle ||
              (props.selectedData?.isWorkingStep &&
                props.selectedData.workingStep.primaryWorkingStep !==
                  props.selectedData.workingStep
                    .secondaryWorkingStep) ? null : (
              <div style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    gap: '.5em',
                    marginBottom: '1em',
                  }}
                >
                  <ProjectTextField
                    value={props.selectedData.surchargeRatio}
                    saveAction={(value) =>
                      CostDriversActions.SaveCostDriverSurchargeRatio({
                        newRatio: value,
                        priceSummary: props.selectedData,
                      })
                    }
                    actionTypePrefix={
                      CostDriversActions.SaveCostDriverSurchargeRatio.typePrefix
                    }
                    textFieldProps={{
                      fullWidth: true,
                      label: t('project:surcharge'),
                      disabled: !projectIsEditable,
                    }}
                    money
                  />
                  {props.selectedData.isSurchargeManuallySet && (
                    <IconButton
                      onClick={handleResetSurchargeToDefault}
                      size="small"
                    >
                      <HomePriceButtonOutlined
                        isLoading={resetingSurcharge}
                        isManuallySet={
                          props.selectedData.isSurchargeManuallySet
                        }
                        handleResetToDefaults={handleResetSurchargeToDefault}
                        hidden={!projectIsEditable}
                      />
                    </IconButton>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    gap: '.5em',
                    marginBottom: '1em',
                  }}
                >
                  <ProjectTextField
                    value={props.selectedData.discountRatio}
                    saveAction={(value) =>
                      CostDriversActions.SaveCostDriverDiscountRatio({
                        newRatio: value,
                        priceSummary: props.selectedData,
                      })
                    }
                    actionTypePrefix={
                      CostDriversActions.SaveCostDriverDiscountRatio.typePrefix
                    }
                    textFieldProps={{
                      fullWidth: true,
                      label: t('project:discount'),
                      disabled: !projectIsEditable,
                    }}
                    money
                    validation={(value) => {
                      const validation = DefinitiveInputValidators.MaxValue(
                        parseFloat(value.toString()),
                        100
                      )()

                      if (validation?.length) {
                        return {
                          isValid: false,
                          errorMessage: validation[0],
                        }
                      }

                      return { isValid: true }
                    }}
                  />
                  <div className="margin-editor-action-buttons">
                    {props.selectedData.isDiscountManuallySet && (
                      <HomePriceButtonOutlined
                        isLoading={resetingDiscount}
                        isManuallySet={props.selectedData.isDiscountManuallySet}
                        handleResetToDefaults={handleResetDiscountToDefault}
                        hidden={!projectIsEditable}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </li>
          <li>
            <label>{t('project:cost-price')}</label>
            <label className="label">
              {MoneyString(props.selectedData.totalCostPrice)}
            </label>
          </li>
          <li>
            <label>{t('project:surcharge')}</label>
            <label className="label">
              {MoneyString(props.selectedData.surchargeValue)}
              <br />
              <small style={{ textAlign: 'right' }}>
                (
                {parseFloat(
                  (props.selectedData?.surchargeRatio?.value || 0).toString()
                ).toFixed(2)}
                %)
              </small>
            </label>
          </li>
          <li>
            <label>{t('project:discount')}</label>
            <label className="label">
              {MoneyString(props.selectedData.discountValue)}
              <br />
              <small style={{ textAlign: 'right' }}>
                (
                {parseFloat(
                  (props.selectedData?.discountRatio?.value || 0).toString()
                ).toFixed(2)}
                %)
              </small>
            </label>
          </li>
          <li>
            <label>{t('project:sales-price')}</label>
            <label className="label">
              {MoneyString(props.selectedData.totalSalesPrice)}
            </label>
          </li>
          {props.selectedData.totalSalesPrice.value > 0 ? (
            <>
              <Divider orientation="horizontal" />
              <li>
                <>
                  <Typography variant="caption">
                    {t('project:margin')}
                  </Typography>
                  <Typography variant="caption" className="label">
                    {QuantityString({
                      unit: '%',
                      value:
                        (100 *
                          (props.selectedData.totalSalesPrice.value -
                            props.selectedData.totalCostPrice.value)) /
                        props.selectedData.totalSalesPrice.value,
                      quantityType: QuantityDtoType.Ratio,
                    })}
                  </Typography>
                </>
              </li>
              <Divider orientation="horizontal" />
            </>
          ) : null}
        </ul>
      </div>
    </div>
  )
}

import { ValueLabelConnected } from 'features/BillOfMaterials/store/components/ValueLabelConnected'
// import { projectDiscountOrSurchargeSelector } from 'features/BillOfMaterials/store/selectors/projectDiscountOrSurchargeSelector'
import { Typography } from '@mui/material'
import { ValueLabelProps } from 'components/Common/ValueLabel/ValueLabel'
import { useValueLabelStyles } from 'components/Common/ValueLabel/ValueLabel.styles'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { MoneyString } from 'model/Money'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/configureStore'

// export function ProjectDiscountOrSurcharge() {
//   const { t } = useTranslation()
//   return (
//     <ValueLabelConnected
//       propertySelector={projectDiscountOrSurchargeSelector}
//       valueLabelProps={{
//         label: t('project:discount-surcharge'),
//       }}
//     />
//   )
// }

export function ProjectSurchargeRatio(props: {
  valueLabelClasses?: ValueLabelProps['classes']
}) {
  const { t } = useTranslation()

  return (
    <ValueLabelConnected
      propertySelector={projectSelectors.projectSurchargeRatioSelector}
      valueLabelProps={{
        label: t('common:surcharge', 'surcharge'),
        emptyString: '-',
        classes: props.valueLabelClasses,
      }}
    />
  )
}

export function ProjectSurchargeValueAndRatio(props: {
  valueLabelClasses?: {
    root?: string
    ratio?: string
  }
}) {
  const { t } = useTranslation()
  const { classes } = useValueLabelStyles()
  const surchargeValue = useAppSelector(
    projectSelectors.projectSurchargeValueSelector
  )
  const surchargeRatio = useAppSelector(
    projectSelectors.projectSurchargeRatioSelector
  )

  return (
    <div
      className={[
        'valueLabel',
        classes.valueLabelRoot,
        props.valueLabelClasses?.root,
      ].join(' ')}
    >
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.label}
      >
        {t('common:surcharge', 'surcharge')}
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {MoneyString(surchargeValue)}
      </Typography>
      <Typography
        variant="caption"
        color="textSecondary"
        className={[classes.label, props.valueLabelClasses?.ratio].join(' ')}
        style={{ paddingTop: '.7em' }}
      >
        {surchargeRatio}
      </Typography>
    </div>
  )
}

export function ProjectDiscountValueAndRatio(props: {
  valueLabelClasses?: {
    root?: string
    ratio?: string
  }
}) {
  const { t } = useTranslation()
  const { classes } = useValueLabelStyles()
  const discountValue = useAppSelector(
    projectSelectors.projectDiscountValueSelector
  )
  const discountRatio = useAppSelector(
    projectSelectors.projectDiscountRatioSelector
  )

  return (
    <div
      className={[
        'valueLabel',
        classes.valueLabelRoot,
        props.valueLabelClasses?.root,
      ].join(' ')}
    >
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.label}
      >
        {t('common:discount', 'discount')}
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {MoneyString(discountValue)}
      </Typography>
      <Typography
        variant="caption"
        color="textSecondary"
        className={[classes.label, props.valueLabelClasses?.ratio].join(' ')}
        style={{ paddingTop: '.7em' }}
      >
        {discountRatio}
      </Typography>
    </div>
  )
}

export function ProjectDiscountRatio(props: {
  valueLabelClasses?: ValueLabelProps['classes']
}) {
  const { t } = useTranslation()

  return (
    <ValueLabelConnected
      propertySelector={projectSelectors.projectDiscountRatioSelector}
      valueLabelProps={{
        label: t('common:discount', 'discount'),
        emptyString: '-',
        classes: props.valueLabelClasses,
      }}
    />
  )
}

import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  onClick?: () => void
  disabled?: boolean
  labelTranslationKey?: string
  labelDefaultValue?: string
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  size?: 'small' | 'medium' | 'large'
  children?: React.ReactElement
  variant?: 'text' | 'outlined' | 'contained'
  removeMargin?: boolean
}

export const CancelButton = (props: Props) => {
  const { t } = useTranslation()
  return (
    <Button
      type="reset"
      color={props.color || 'secondary'}
      variant={props.variant || 'outlined'}
      onClick={props.onClick}
      disabled={props.disabled}
      size={props.size}
      style={{ marginRight: !props.removeMargin ? '.5em' : '0' }}
    >
      {props.children ||
        t(
          props.labelTranslationKey ?? 'common:cancel',
          props.labelDefaultValue ?? 'cencel'
        )}
    </Button>
  )
}

import {
  default as ValueLabel,
  ValueLabelProps,
} from 'components/Common/ValueLabel/ValueLabel'
import { isEqual } from 'lodash'
import { MoneyString } from 'model/Money'
import { QuantityString } from 'model/Quantity'
import { ElementType } from 'react'
import {
  MoneyDto,
  QuantityDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState, useAppSelector } from 'store/configureStore'

export const ValueLabelConnected = (props: {
  propertySelector: (
    state: RootAppState
  ) => string | number | QuantityDto | MoneyDto
  valueLabelProps?: Partial<ValueLabelProps>
  showEmptyValue?: boolean
  hideValue?: boolean
  hiddenFallbackComponent?: ElementType
}) => {
  const valueFromStore = useAppSelector(props.propertySelector, isEqual)

  if (
    (valueFromStore == null || valueFromStore === '-') &&
    props.showEmptyValue === false
  ) {
    if (props.hiddenFallbackComponent) {
      return <props.hiddenFallbackComponent />
    }

    return null
  }

  let valueToUse: string | number = ''

  if (props.hideValue) {
    valueToUse = '-'
  } else {
    if (valueFromStore?.hasOwnProperty('unit')) {
      valueToUse = QuantityString(valueFromStore as QuantityDto)
    } else if (valueFromStore?.hasOwnProperty('currency')) {
      valueToUse = MoneyString(valueFromStore as MoneyDto)
    } else {
      valueToUse = valueFromStore as string | number
    }
  }

  return <ValueLabel {...props.valueLabelProps} value={valueToUse} />
}

import { InputAdornment } from '@mui/material'
import { TFunction } from 'i18next'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  QuantityDto,
  QuantityDtoType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { NumberInput, NumberInputProps } from './NumberInput'
import { UnitSelector, UnitSelectorProps } from './UnitSelector'

export type NewQuantityProps = {
  quantity: QuantityDto
  onChangeQuantity: (
    newQuantity: QuantityDto,
    errorSchema: Record<string, string>
  ) => void
  minValue?: number
  maxValue?: number
  onValidation?: (valid) => void
} & NumberInputProps &
  UnitSelectorProps

const validate = (
  t: TFunction,
  quantity: QuantityDto,
  maxValue: number,
  minValue: number,
  quantityDtoType: QuantityDtoType
) => {
  const error = {}

  if (quantity.value < minValue) {
    error['value'] = t('common:min-value', { minValue: minValue })
  }

  if (quantity.value > maxValue) {
    error['value'] = t('common:max-value', { maxValue: maxValue })
  }

  if (quantityDtoType !== QuantityDtoType.Undefined && !quantity.unit) {
    error['unit'] = t('common:required')
  }

  return error
}

export function NewQuantity(props: NewQuantityProps) {
  const [validation, setValidation] = React.useState<Record<string, string>>()
  const { t } = useTranslation()

  const internalValidation = React.useCallback(
    (quantity: QuantityDto) => {
      const validation = validate(
        t,
        quantity,
        props.maxValue,
        props.minValue,
        props.quantityDtoType
      )

      props.onValidation &&
        props.onValidation(_.isEmpty(validation) ? undefined : validation)

      setValidation(_.isEmpty(validation) ? undefined : validation)

      return validation
    },
    [props, t]
  )

  const handleChange = (quantity: QuantityDto) => {
    props.onChangeQuantity(quantity, internalValidation(quantity))
  }

  React.useEffect(() => {
    internalValidation(props.quantity)
  }, [internalValidation, props.quantity])

  return (
    <NumberInput
      value={props.quantity.value}
      label={props.label}
      onFocus={(e) => e.target.select()}
      onChange={(e) => {
        let valueAsNumber = parseFloat(e.target.value)
        valueAsNumber = isNaN(valueAsNumber) ? 0 : valueAsNumber
        handleChange({
          ...props.quantity,
          value: valueAsNumber,
        })
      }}
      required={props.required}
      helperText={validation && validation['value']}
      error={Boolean(validation && validation['value'])}
      InputProps={{
        fullWidth: props.fullWidth,
        endAdornment: (
          <InputAdornment position="end">
            <>
              <UnitSelector
                quantityDtoType={props.quantity.quantityType}
                value={props.quantity.unit}
                onChange={(e) =>
                  handleChange({
                    ...props.quantity,
                    unit: e.target.value as string,
                  })
                }
              />
            </>
          </InputAdornment>
        ),
      }}
    />
  )
}

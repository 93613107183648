import { RefreshOutlined } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import Spinning from 'components/Common/Spinner/Spinning'
import { Container } from 'components/Layout/Container'
import { LocalizedTypography } from 'components/Localization/LocalizedTypography'
import useLocalizedMaterialReactTable from 'components/ProjectsPage/useLocalizedMaterialReactTable'
import { useClientStorage } from 'customHooks/useClientStorage'
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_GlobalFilterTextField,
} from 'material-react-table'
import queryString from 'query-string'
import { HTMLProps, useMemo } from 'react'
import { Navigate } from 'react-router-dom'
import {
  PartySummaryDto,
  PortalInfo,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { Debugger } from 'utils/debugger'
import { Key } from 'utils/keyCodes'
import { NoAvailablePortals } from './NoAvailablePortals'
import { ProjectNotAllowedInWorkspace } from './ProjectNotAllowedInWorkspace'
import { useWorkspaces } from './useWorkspaces'

const PageTitle = (props: HTMLProps<HTMLDivElement>) => (
  <div {...props}>
    <LocalizedTypography
      translationKey="common:my-workspaces"
      variant="h4"
      color="primary"
    >
      workspaces
    </LocalizedTypography>
    <LocalizedTypography
      translationKey="common:my-workspaces--subtitle"
      variant="subtitle1"
      color="textSecondary"
      component="p"
    >
      select a workspace to start
    </LocalizedTypography>
  </div>
)

const Workspaces = () => {
  const {
    auth0,
    loading,
    location,
    organizations,
    currentPartyId,
    currentPartyName,
    availablePortals,
    classes,
    getProfileData,
    controller,
    withinIframe,
    selectWorkspace,
  } = useWorkspaces()

  const { returnTo } = queryString.parse(window.location.search)

  const [storageReturnUrl] = useClientStorage(
    'loginWithRedirectReturnUrl',
    location.state?.returnUrl || ''
  )

  const factoriesColumns: MRT_ColumnDef<PartySummaryDto>[] = useMemo(() => {
    return [
      {
        header: '',
        id: 'name',
        accessorFn: (row) => row.name,
      },
    ]
  }, [])

  const factoriesTable = useLocalizedMaterialReactTable({
    columns: factoriesColumns,
    data: organizations || [],
    enableBottomToolbar: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableHiding: false,
    enableColumnActions: false,
    enableSorting: false,
    initialState: {
      showGlobalFilter: true,
      density: 'comfortable',
    },
    state: {
      isLoading: loading['get-profile'],
    },
    muiTableBodyRowProps: (rowProps) => {
      return {
        onClick: () => {
          selectWorkspace(rowProps.row.original.id)
        },
        style: {
          cursor: 'pointer',
        },
      }
    },
    muiTableContainerProps: {
      style: {
        maxHeight: 'calc(100vh - 280px)',
      },
    },
    renderTopToolbar: (toolbarProps) => {
      return (
        <Box
          padding={2}
          display="flex"
          alignItems="center"
          gap={2}
          justifyContent={'space-between'}
        >
          {withinIframe ? (
            <div />
          ) : (
            <LocalizedTypography
              translationKey="common:factories"
              variant="h6"
              textOverflow={'ellipsis'}
              whiteSpace={'nowrap'}
              overflow={'hidden'}
            />
          )}
          <Box display="flex">
            <MRT_GlobalFilterTextField
              table={toolbarProps.table}
              variant="standard"
              inputProps={{
                onKeyUp: (event) => {
                  if (!event.currentTarget.value) {
                    return
                  }

                  if (event.key === Key.Enter) {
                    setTimeout(() => {
                      const row = toolbarProps.table.getRowModel().rows?.[0]

                      if (row && row.original.id) {
                        selectWorkspace(row.original.id)
                      }
                    }, 250)
                  }
                },
              }}
            />
            <IconButton
              onClick={() => getProfileData()}
              disabled={loading['get-profile']}
              size="small"
            >
              <RefreshOutlined />
            </IconButton>
          </Box>
        </Box>
      )
    },
  })

  const availablePortalsColumns: MRT_ColumnDef<PortalInfo>[] = useMemo(() => {
    return [
      {
        header: '',
        id: 'name',
        accessorFn: (row) => row.companyName,
      },
      {
        header: '',
        id: 'url',
        accessorFn: (row) => row.url,
        muiTableBodyCellProps: {
          align: 'right',
        },
      },
    ]
  }, [])

  const selfServicePortalTable = useLocalizedMaterialReactTable({
    columns: availablePortalsColumns,
    data: availablePortals || [],
    enableBottomToolbar: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableHiding: false,
    enableColumnActions: false,
    enableSorting: false,
    initialState: {
      showGlobalFilter: true,
      density: 'comfortable',
    },
    state: {
      isLoading: loading['get-profile'],
    },
    muiTableBodyRowProps: (rowProps) => {
      return {
        onClick: () => {
          window.location.assign(rowProps.row.original.url)
        },
        onAuxClick: () => {
          window.open(rowProps.row.original.url, '_blank')
        },
        style: {
          cursor: 'pointer',
        },
      }
    },
    muiTableContainerProps: {
      style: {
        maxHeight: 'calc(100vh - 280px)',
      },
    },
    renderTopToolbar: (toolbarProps) => {
      return (
        <Box
          padding={2}
          display="flex"
          alignItems="center"
          gap={2}
          justifyContent={'space-between'}
        >
          <LocalizedTypography
            translationKey="common:self-service-portal"
            variant="h6"
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
          />
          <Box display="flex">
            <MRT_GlobalFilterTextField
              table={toolbarProps.table}
              variant="standard"
              inputProps={{
                onKeyUp: (event) => {
                  if (!event.currentTarget.value) {
                    return
                  }

                  if (event.key === Key.Enter) {
                    setTimeout(() => {
                      const row = toolbarProps.table.getRowModel().rows?.[0]

                      if (row && row.original.url) {
                        window.location.replace(row.original.url)
                      }
                    }, 250)
                  }
                },
              }}
            />
            <IconButton
              onClick={() => getProfileData()}
              disabled={loading['get-profile']}
              size="small"
            >
              <RefreshOutlined />
            </IconButton>
          </Box>
        </Box>
      )
    },
  })

  console.log(loading)
  if (
    auth0.isLoading ||
    !organizations ||
    loading['get-workspace-configuration']
  ) {
    return <Spinning fixed />
  }

  const returnUrl = location.state?.returnUrl || returnTo || storageReturnUrl
  const notAllowedProjectId = location.state?.projectNotAllowedId

  if (
    currentPartyId &&
    !notAllowedProjectId &&
    returnUrl &&
    returnUrl !== '/app/workspaces'
  ) {
    Debugger.Info('routes: workspaces page, redirecting to', {
      returnUrl,
      currentPartyId,
    })

    return <Navigate to={returnUrl} state={location.state} replace />
  }

  if (
    organizations?.length === 0 &&
    (!availablePortals || availablePortals?.length === 0)
  ) {
    return (
      <NoAvailablePortals
        controller={controller}
        getProfileData={getProfileData}
        loading={loading}
      />
    )
  }

  Debugger.Info('routes: workingspaces page, no redirect', {
    currentPartyId,
    returnUrl,
  })

  return (
    <div className={classes.workspacesRoot}>
      <Container maxWidth="xl">
        <div className={classes.toolbar}>
          <PageTitle className={classes.pageTitle} />
          {location.state?.projectNotAllowedId && currentPartyName && (
            <ProjectNotAllowedInWorkspace
              projectId={location.state?.projectNotAllowedId}
              currentPartyName={currentPartyName}
            />
          )}
        </div>
        <div className={classes.workspaces}>
          {organizations?.length ? (
            <div className={classes.workspaceTable}>
              <MaterialReactTable table={factoriesTable} />
            </div>
          ) : null}
          {availablePortals?.length ? (
            <div className={classes.workspaceTable}>
              <MaterialReactTable table={selfServicePortalTable} />
            </div>
          ) : null}
        </div>
      </Container>
    </div>
  )
}

export default Workspaces

import { FilterAltOffOutlined, FilterAltOutlined } from '@mui/icons-material'
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { SaveAndResetButtons } from 'components/Common/DialogActionButtons/SaveAndResetButtons'
import { MoneyPerQuantityInput } from 'components/Common/Input/MoneyPerQuantityInput'
import { ProjectArticleController } from 'controllers/Project/ProjectArticleController'
import { useAppController } from 'customHooks/useAppController'
import { useArticleIdFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/ArticleIdFilter/useArticleIdFilter'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { HomePriceButtonOutlined } from 'features/BomItemDetailsDrawer/BomItemDetails/ProjectDetails/ProjectPriceSummaries/HomePriceButtonOutlined'
import { getSelectableAbbreviations } from 'features/measurementSystem/utils/selectableAbbreviations'
import { isEqual } from 'lodash'
import { MoneyString } from 'model/Money'
import { QuantityString } from 'model/Quantity'
import React, { FormEvent, Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MoneyDto,
  MoneyPerQuantityDto,
  PriceSummaryDto,
  QuantityDtoType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { getPriceSummaryKey } from 'store/Project/ProjectStateUtils'
import { useAppSelector } from 'store/configureStore'
import { useOverviewCardStyles } from '../../components/useDashboardCardStyles'
import { useProjectMaterialsCardStyles } from '../useProjectMaterialsCardStyles'
import { SurchargeAndDiscountSmallTable } from './SurchargeAndDiscountSmallTable'

const MoneyPerQuantityWithDefault = (
  originalMoneyPerQuantity: MoneyPerQuantityDto,
  defaultMoneyDto: MoneyDto
) => {
  const moneyPerQuantity: MoneyPerQuantityDto = {
    money: {
      currency:
        originalMoneyPerQuantity?.money.currency || defaultMoneyDto.currency,
      value: originalMoneyPerQuantity?.money.value || 0,
      symbol: originalMoneyPerQuantity?.money.symbol || defaultMoneyDto.symbol,
    },
    quantity: {
      ...originalMoneyPerQuantity.quantity,
      value: originalMoneyPerQuantity.quantity.value || 1,
    },
  }

  return moneyPerQuantity
}

const MaterialPriceSummaryItem = (props: {
  materialPriceSummary: PriceSummaryDto
  defaultMoneyObj: MoneyDto
}) => {
  const { t } = useTranslation()
  const { filterIsActive, handleChangeFilter } = useArticleIdFilter()
  const { classes } = useProjectMaterialsCardStyles()
  const { classes: cardClasses } = useOverviewCardStyles()

  const { controller, loading } = useAppController(
    () => new ProjectArticleController()
  )

  const projectIsEditable = useAppSelector(
    projectSelectors.ProjectEditableStateSelector
  )

  const [_rate, _setRate] = React.useState<MoneyPerQuantityDto | null>(() => {
    return MoneyPerQuantityWithDefault(
      props.materialPriceSummary.rate,
      props.defaultMoneyObj
    )
  })

  useEffect(() => {
    _setRate(
      MoneyPerQuantityWithDefault(
        props.materialPriceSummary.rate,
        props.defaultMoneyObj
      )
    )
  }, [props.defaultMoneyObj, props.materialPriceSummary.rate])

  const onHandleCostPrice = (costPrice: MoneyPerQuantityDto) => {
    _setRate(costPrice)
  }

  const handleReset = () => {
    _setRate(
      MoneyPerQuantityWithDefault(
        props.materialPriceSummary.rate,
        props.defaultMoneyObj
      )
    )
  }

  const handleSaveCostPrice = (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()
      if (!isEqual(_rate, props.materialPriceSummary.rate)) {
        controller.SaveArticleCostPrice(
          getPriceSummaryKey(props.materialPriceSummary),
          props.materialPriceSummary.materialId,
          _rate
        )
      }
    } catch (error) {
      ShowException('Error saving cost price', error)
    }
  }

  const handleDeleteCostPrice = async () => {
    try {
      await controller.DeleteArticleCostPrice(
        props.materialPriceSummary.materialId
      )
    } catch (err) {
      ShowException('unable to reset price', err)
    }
  }

  if (!props.materialPriceSummary) {
    return null
  }

  return (
    <Fragment>
      <TableRow
        sx={{
          '& td': {
            borderBottom: 'none',
          },
        }}
      >
        <TableCell colSpan={3}>
          <Typography
            variant="caption"
            component="p"
            fontWeight={'bold'}
            maxWidth="30vw"
          >
            {props.materialPriceSummary.materialId} -{' '}
            {props.materialPriceSummary.description}
          </Typography>
        </TableCell>
        <TableCell>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.usage}
              component="p"
              noWrap
            >
              {`${QuantityString(props.materialPriceSummary.weight)}`}
            </Typography>
            {isEqual(
              props.materialPriceSummary.totalQuantity,
              props.materialPriceSummary.weight
            ) ? null : (
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.usage}
                noWrap
              >
                {`${
                  isEqual(
                    props.materialPriceSummary.totalQuantity,
                    props.materialPriceSummary.weight
                  )
                    ? ''
                    : `(${QuantityString(
                        props.materialPriceSummary.totalQuantity
                      )})`
                }`}
              </Typography>
            )}
          </div>
        </TableCell>
        <TableCell style={{ textAlign: 'right' }}>
          <Tooltip
            title={t('project:filter-by-article-id', 'filter by article')}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                handleChangeFilter([props.materialPriceSummary.materialId])
              }}
              style={{ alignSelf: 'center' }}
              sx={{
                '& svg': {
                  fill: (theme) => theme.palette.text.primary,
                  width: '1em',
                },
              }}
              size="small"
              color="primary"
            >
              {filterIsActive(props.materialPriceSummary.materialId) ? (
                <FilterAltOffOutlined />
              ) : (
                <FilterAltOutlined />
              )}
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ textAlign: 'right', verticalAlign: 'top' }}>
          <form
            onSubmit={handleSaveCostPrice}
            style={{
              display: 'flex',
              gap: '1em',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <MoneyPerQuantityInput
                rootClassName={cardClasses.rateInput}
                value={_rate}
                onChange={(e) =>
                  onHandleCostPrice(
                    e.currentTarget.value as unknown as MoneyPerQuantityDto
                  )
                }
                name="rate"
                defaultUnits={[
                  ...getSelectableAbbreviations(QuantityDtoType.Length),
                  ...getSelectableAbbreviations(QuantityDtoType.Mass),
                ]}
                defaultMoneyObject={props.defaultMoneyObj}
                disabled={!projectIsEditable}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: '.5em',
                  paddingTop: '.5em',
                }}
              >
                <HomePriceButtonOutlined
                  handleResetToDefaults={handleDeleteCostPrice}
                  isManuallySet={
                    props.materialPriceSummary.isCostPriceManuallySet
                  }
                  isLoading={
                    loading[
                      `delete-cost-price-${props.materialPriceSummary.materialId}`
                    ]
                  }
                  hidden={!projectIsEditable}
                />
                {!isEqual(
                  _rate,
                  MoneyPerQuantityWithDefault(
                    props.materialPriceSummary.rate,
                    props.defaultMoneyObj
                  )
                ) && (
                  <SaveAndResetButtons
                    disabled={
                      loading[
                        `${props.materialPriceSummary.materialId}_costprice`
                      ]
                    }
                    handleReset={handleReset}
                    saving={
                      loading[
                        `${props.materialPriceSummary.materialId}_costprice`
                      ]
                    }
                  />
                )}
              </div>
            </div>
          </form>
        </TableCell>
        <TableCell style={{ verticalAlign: 'top', textAlign: 'right' }}>
          <Typography
            variant="caption"
            component="p"
            style={{ paddingTop: '2px' }}
          >
            {MoneyString(props.materialPriceSummary.totalCostPrice)}
          </Typography>
        </TableCell>
        <TableCell
          colSpan={2}
          style={{ textAlign: 'right', verticalAlign: 'top' }}
        >
          <SurchargeAndDiscountSmallTable
            priceSummary={props.materialPriceSummary}
            noHeaders
          />
        </TableCell>
        <TableCell
          style={{
            textAlign: 'right',
          }}
        >
          <Typography variant="caption" component="p">
            <strong>
              {MoneyString(props.materialPriceSummary.totalSalesPrice)}
            </strong>
          </Typography>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

const MemoizedMaterialPriceSummaryItem = React.memo(
  MaterialPriceSummaryItem,
  isEqual
)

export default MemoizedMaterialPriceSummaryItem

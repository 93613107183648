import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import {
  getUnitDecimalPrecision,
  useSelectableAbbreviations,
} from 'features/measurementSystem/utils/selectableAbbreviations'
import _ from 'lodash'
import React, { FocusEvent, FormEvent, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MoneyDto,
  MoneyPerQuantityDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { NumberFormatCustom } from '../NumberFormatInputComponent'

type Props = {
  value: MoneyPerQuantityDto
  onChange: (
    e: FormEvent<HTMLInputElement>,
    newValue?: MoneyPerQuantityDto
  ) => void
  name: string
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  disabled?: boolean
  saving?: boolean
  defaultUnits?: string[]
  startAdornment?: React.ReactElement
  defaultMoneyObject?: MoneyDto
  label?: (string | number) | ReactNode
  rootClassName?: string
  helperText?: string
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch'
}

function MoneyPerQuantityInput(props: Props) {
  const { t } = useTranslation()

  const selectableAbbreviations = useSelectableAbbreviations(
    props.value?.quantity?.quantityType
  )

  function handleChange(e) {
    const newMoneyPerQuantity = { ...props.value }

    switch (e.currentTarget?.name || e.target?.name) {
      case 'money': {
        newMoneyPerQuantity.money = {
          symbol:
            props.value?.money?.symbol ||
            props.defaultMoneyObject?.symbol ||
            '€',
          currency:
            props.value?.money?.currency ||
            props.defaultMoneyObject?.currency ||
            'EUR',
          value: e.currentTarget.value,
        }
        newMoneyPerQuantity.quantity = {
          ...props.value.quantity,
          unit:
            props.value.quantity?.unit ||
            (props.defaultUnits && props.defaultUnits[0]),
        }
        break
      }
      case 'quantity': {
        newMoneyPerQuantity.money = {
          ...props.value?.money,
          symbol:
            props.value?.money?.symbol ||
            props.defaultMoneyObject?.symbol ||
            '€',
          currency:
            props.value?.money?.currency ||
            props.defaultMoneyObject?.currency ||
            'EUR',
        }
        newMoneyPerQuantity.quantity = {
          ...props.value.quantity,
          value: e.currentTarget.value || 1,
          unit:
            props.value?.quantity?.unit ||
            (props.defaultUnits && props.defaultUnits[0]),
        }
        break
      }
      case 'unit': {
        newMoneyPerQuantity.quantity = {
          ...props.value.quantity,
          unit: e.target.value,
        }
        break
      }
    }

    if (!_.isEqual(newMoneyPerQuantity, props.value)) {
      props.onChange(
        {
          ...e,
          currentTarget: {
            ...e.currentTarget,
            value: newMoneyPerQuantity,
            name: props.name,
          },
        },
        newMoneyPerQuantity
      )
    }
  }

  return (
    <div
      className={props.rootClassName}
      style={{ display: 'flex', alignItems: props.alignItems || 'flex-end' }}
      onClick={(e) => e.stopPropagation()}
      onDoubleClick={(e) => e.stopPropagation()}
    >
      <TextField
        // disabled={isLoading[`${props.ps.materialId || props.ps.workingStep.primaryWorkingStep}_costprice`]}
        disabled={props.disabled}
        value={props.value?.money?.value}
        name="money"
        label={props.label}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography color="textSecondary">
                {props.value?.money?.symbol || '€'}
              </Typography>
              {props.startAdornment}
            </InputAdornment>
          ),
          inputComponent: NumberFormatCustom as never,
          inputProps: {
            style: { textAlign: 'right' },
            allowNegative: false,
            allowLeadingZeros: false,
          },
        }}
        onBlur={props.onBlur}
        onFocus={(e) => {
          e.target.select()
          props.onFocus && props.onFocus(e as never)
        }}
        helperText={props.saving && t('common:saving')}
        // style={{ width: 'auto' }}
      />
      <span style={{ margin: '0 0' }}> / </span>
      <TextField
        disabled={props.disabled}
        value={props.value.quantity?.value ?? ''}
        name="quantity"
        onChange={handleChange}
        // style={{ width: '50%', minWidth: '100px' }}
        style={{ width: 'max-content' }}
        onBlur={props.onBlur}
        onFocus={(e) => {
          e.target.select()
          props.onFocus && props.onFocus(e as never)
        }}
        InputProps={{
          inputComponent: NumberFormatCustom as never,
          inputProps: {
            decimalScale: getUnitDecimalPrecision(
              props.value.quantity?.unit,
              0
            ),
            fixedDecimalScale: false,
            style: { textAlign: 'right', minWidth: '2ch' },
            allowNegative: false,
            isAllowed: (value) =>
              value.formattedValue === '' || (value.floatValue || 0) > 0,
          },
          endAdornment: (
            <InputAdornment position="end" style={{ marginLeft: '6px' }}>
              <Select
                name="unit"
                margin="dense"
                value={
                  props.value.quantity?.unit ||
                  (props.defaultUnits && props.defaultUnits[0]) ||
                  ''
                }
                disableUnderline
                disabled={props.disabled}
                style={{
                  alignSelf: 'center',
                  // paddingTop: '4px',
                  // width: '46px',
                }}
                onChange={handleChange}
              >
                {(
                  (selectableAbbreviations?.length
                    ? selectableAbbreviations
                    : props.defaultUnits) as string[]
                )?.map((unit) => {
                  return (
                    <MenuItem value={unit} key={unit}>
                      {unit}
                    </MenuItem>
                  )
                })}
              </Select>
            </InputAdornment>
          ),
        }}
        helperText={(props.saving && t('common:saving')) || props.helperText}
      />
    </div>
  )
}

export { MoneyPerQuantityInput }

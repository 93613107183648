import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { QuantityString } from 'model/Quantity'
import { useTranslation } from 'react-i18next'
import {
  CuttingPlanDetailsDto,
  QuantityDtoType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  center: {
    textAlign: 'center',
  },
  table: {
    '& tbody tr td': {
      fontSize: theme.typography.caption.fontSize,
      whiteSpace: 'nowrap',
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightBold,
    },
    '& thead tr th': {
      fontSize: theme.typography.caption.fontSize,
      whiteSpace: 'nowrap',
      textAlign: 'center',
    },
  },
}))

export function CuttingPlanDetails(props: {
  details: CuttingPlanDetailsDto
  size?: 'small' | 'medium'
}) {
  const { classes } = useStyles()
  const { t } = useTranslation()

  if (!props.details) {
    return null
  }

  return (
    <Table size={props.size || 'medium'} className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.center}>
            {t('project:material-dimensions', 'material dimensions')}
          </TableCell>
          <TableCell className={classes.center}>
            {t('project:material-area', 'material area')}
          </TableCell>
          <TableCell className={classes.center}>
            {t('project:material-utilization', 'material utilization')}
          </TableCell>
          <TableCell className={classes.center}>
            {t('project:material-nestable-area', 'nestable area')}
          </TableCell>
          <TableCell className={classes.center}>
            {t(
              'project:material-nestable-area-utilization',
              'nestable area utilization'
            )}
          </TableCell>
          <TableCell className={classes.center}>
            {t('project:material-parts', 'parts')}
          </TableCell>
          <TableCell className={classes.center}>
            {t('project:material-uniq-parts', 'uniq parts')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className={classes.center}>
            {QuantityString(props.details.sheetLength)}
            {' x '}
            {QuantityString(props.details.sheetWidth)}
          </TableCell>

          <TableCell className={classes.center}>
            {QuantityString(props.details.sheetArea)}
          </TableCell>
          <TableCell className={classes.center}>
            {QuantityString({
              value: props.details.sheetUtilization,
              unit: '%',
              quantityType: QuantityDtoType.Ratio,
            })}
          </TableCell>
          <TableCell className={classes.center}>
            {QuantityString(props.details.nestableArea)}
          </TableCell>

          <TableCell className={classes.center}>
            {QuantityString({
              value: props.details.offsettedSheetUtilization,
              unit: '%',
              quantityType: QuantityDtoType.Ratio,
            })}
          </TableCell>
          <TableCell className={classes.center}>
            {QuantityString({
              value: props.details.totalPartQuantity,
              unit: '',
              quantityType: QuantityDtoType.Undefined,
            })}
          </TableCell>
          <TableCell className={classes.center}>
            {QuantityString({
              value: props.details.uniquePartQuantity,
              unit: '',
              quantityType: QuantityDtoType.Undefined,
            })}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

import { MenuItem, Select, SelectProps } from '@mui/material'
import { useSelectableAbbreviations } from 'features/measurementSystem/utils/selectableAbbreviations'
import { QuantityDtoType } from 'services/APIs/InternalAPI/internal-api.contracts'

export type UnitSelectorProps = {
  quantityDtoType: QuantityDtoType
} & SelectProps

export function UnitSelector(props: UnitSelectorProps) {
  const { quantityDtoType, ...selectProps } = props

  const selectableAbbreviations = useSelectableAbbreviations(quantityDtoType)

  if (!selectableAbbreviations?.length) {
    if (props.value) {
      return <span>{props.value.toString()}</span>
    } else {
      return null
    }
  }

  return (
    <Select {...selectProps} variant="standard">
      {selectableAbbreviations.map((unit) => {
        return (
          <MenuItem value={unit} key={unit}>
            {unit}
          </MenuItem>
        )
      })}
    </Select>
  )
}

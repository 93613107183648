import {
  InputAdornment,
  MenuItem,
  Select,
  Typography,
  TypographyVariant,
  useTheme,
} from '@mui/material'
import { NumberParts } from 'components/Common/Input/NumberParts'
import {
  getUnitDecimalPrecision,
  useSelectableAbbreviations,
} from 'features/measurementSystem/utils/selectableAbbreviations'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QuantityDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import {
  DefinitiveDecimalTextField,
  DefinitiveDecimalTextFieldProps,
} from './DefinitiveDecimalTextField'

export type DefinitiveQuantityFieldProps = {
  quantityDto: QuantityDto
  onChange: (value: QuantityDto) => void
  validation?: (value: QuantityDto) => string[]
  onValidation?: (validations: string[]) => void
  showRawValueInTooltip?: (value: QuantityDto) => string
  typographyVariant?: TypographyVariant
  maxValue?: number
  disabledUnit?: boolean
  disabledValue?: boolean
  showThousandsSeparator?: boolean
} & Omit<DefinitiveDecimalTextFieldProps, 'value' | 'onChange'>

export const DefinitiveQuantityField = (
  props: DefinitiveQuantityFieldProps
) => {
  const [currentQuantity, setCurrentQuantity] = useState(props.quantityDto)
  const [currentValidations, setCurrentValidations] =
    useState<string[]>(undefined)

  const selectableAbbreviations = useSelectableAbbreviations(
    props.quantityDto?.quantityType
  )

  const { i18n } = useTranslation()

  const decimalSeparator = useMemo(() => {
    return NumberParts(i18n.language, 'decimal')
  }, [i18n.language])

  const thousandSeparator = useMemo(() => {
    return NumberParts(i18n.language, 'group')
  }, [i18n.language])

  const theme = useTheme()

  useEffect(() => {
    setCurrentQuantity(props.quantityDto)

    const validations = props.validation
      ? props.validation(props.quantityDto)
      : undefined

    if (validations?.length > 0) {
      setCurrentValidations(validations)
    } else {
      setCurrentValidations(undefined)
    }

    props.onValidation?.(validations)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.quantityDto])

  const handleChangeValue = useCallback(
    (value: number) => {
      if (value === currentQuantity.value) {
        return
      }

      const newQuantity = {
        ...currentQuantity,
      }

      newQuantity.value = value

      setCurrentQuantity(newQuantity)

      const validations = props.validation
        ? props.validation(newQuantity)
        : undefined

      if (validations?.length > 0) {
        setCurrentValidations(validations)
      } else {
        setCurrentValidations(undefined)
        props.onChange(newQuantity)
      }
      props.onValidation?.(validations)
    },
    [currentQuantity, props]
  )

  const handleChangeUnit = useCallback(
    (unit: string) => {
      const newQuantity = {
        ...currentQuantity,
      }

      newQuantity.unit = unit

      setCurrentQuantity(newQuantity)

      const validations = props.validation
        ? props.validation(newQuantity)
        : undefined

      if (validations?.length > 0) {
        setCurrentValidations(validations)
      } else {
        setCurrentValidations(undefined)
        props.onChange(newQuantity)
      }
      props.onValidation?.(validations)
    },
    [currentQuantity, props]
  )

  if (!props.quantityDto || !currentQuantity) {
    return null
  }

  return (
    <DefinitiveDecimalTextField
      {...props}
      numberFormatProps={{
        decimalScale:
          props.numberFormatProps?.decimalScale ??
          getUnitDecimalPrecision(
            currentQuantity.unit,
            props.numberFormatProps?.decimalScale ?? 2
          ),
        fixedDecimalScale: true,
        allowNegative: props.numberFormatProps?.allowNegative ?? false,
        decimalSeparator: decimalSeparator,
        thousandSeparator: props.showThousandsSeparator
          ? thousandSeparator
          : '',
        ...props.numberFormatProps,
      }}
      textFieldProps={{
        ...(props.textFieldProps || {}),
        disabled: props.textFieldProps?.disabled ?? props.disabledValue,
        inputProps: {
          ...props.textFieldProps?.inputProps,
          style: {
            textAlign:
              props.textFieldProps?.inputProps?.style?.textAlign || 'right',
            fontSize:
              theme.typography[props.typographyVariant || 'caption'].fontSize,
            padding:
              props.textFieldProps?.inputProps?.style?.padding || '.1em .0em',
            ...(props.textFieldProps?.inputProps?.style || {}),
            minWidth: '3em',
            width: '100%',
          },
        },
        InputProps: {
          endAdornment: selectableAbbreviations.length && (
            <InputAdornment position="end">
              {props.disabledUnit || selectableAbbreviations?.length < 2 ? (
                <Typography
                  variant={props.typographyVariant || 'caption'}
                  color="textSecondary"
                >
                  {currentQuantity?.unit}
                </Typography>
              ) : (
                <Select
                  value={currentQuantity?.unit}
                  onChange={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    handleChangeUnit(e.target.value as string)
                  }}
                  disabled={
                    props.disabledUnit || selectableAbbreviations?.length < 2
                  }
                  error={currentValidations?.length > 0}
                  color={
                    currentValidations?.length > 0 ? 'secondary' : 'primary'
                  }
                  style={{
                    fontSize:
                      theme.typography[props.typographyVariant || 'caption']
                        .fontSize,
                    // width: '4rem',
                  }}
                  disableUnderline
                  readOnly={props.disabledUnit}
                >
                  {selectableAbbreviations.map((abbreviation) => (
                    <MenuItem key={abbreviation} value={abbreviation}>
                      {abbreviation}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </InputAdornment>
          ),
          ...props.textFieldProps?.InputProps,
        },
      }}
      maxValue={props.maxValue}
      value={currentQuantity?.value}
      onChange={(value) => {
        console.log(value)
        handleChangeValue(value)
      }}
      warnings={currentValidations}
      showRawValueInTooltip={props.showRawValueInTooltip}
    />
  )
}
